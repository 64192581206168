import {getFetchedShopifySectionById} from '@/utils/getFetchedShopifySectionById';
export class SortBy extends HTMLElement {
  dom: {
    container: HTMLElement;
    radios: HTMLInputElement[];
    collectionWithFacets: HTMLElement | null;
    currentValue: HTMLElement;
  };

  constructor() {
    super();
    this.dom = {
      container: this,
      radios: Array.from(this.querySelectorAll('input[type="radio"]')) as HTMLInputElement[],
      collectionWithFacets: document.getElementById('collection-with-facets'),
      currentValue: this.querySelector('[data-element=current-value]') as HTMLElement,
    };
    
    this.dom.radios.forEach((radio: HTMLInputElement) => {
      radio.addEventListener('change', () => {
        this.fetchResults(radio.value);
      });
    });
  }
  
  fetchResults = async (value: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('sort_by', value);

    const search = urlParams.toString() ? `${urlParams.toString()}` : '';

    const sectionId = this.dataset.sectionId;
    const response = await fetch(
      `${window.location.origin}${window.location.pathname}?section_id=${sectionId}&${search}`
    );

    const text = await response.text();

    const markup = getFetchedShopifySectionById(text, this.dom.collectionWithFacets?.id ?? '');
    const parser = new DOMParser();
    const resultsMarkup = parser.parseFromString(markup, 'text/html');

    if (resultsMarkup.body) {
      const currentSort = resultsMarkup.body.querySelector<HTMLElement>('[data-element=collection]')?.dataset.sortBy;
      const sortingName = this.dom.radios.find((radio: HTMLInputElement) => {
        return radio.value === currentSort;
      })?.parentElement?.textContent;

      if (sortingName) {
        this.dom.currentValue.innerHTML = sortingName;
      }

      if (this.dom.collectionWithFacets) {
        this.dom.collectionWithFacets.innerHTML = resultsMarkup.body.innerHTML;
      }
    }

    window.history.replaceState({searchParams: urlParams.toString()}, '', `${window.location.pathname}?${search}`);
  };
}
