export class LocalizationForm extends HTMLElement {
  elements: {
    hiddenInput: HTMLInputElement | null;
    button: HTMLElement | null;
    panel: HTMLElement | null;
  };

  constructor() {
    super();
    this.elements = {
      hiddenInput: this.querySelector('#hidden-input'),
      button: this.querySelector('button[id="selector-btn"]'),
      panel: this.querySelector('[data-element="disclosure-element"]'),
    };

    this.querySelectorAll('a').forEach((item) => item.addEventListener('click', this.onItemClick.bind(this)));
  }

  onItemClick(event: Event) {
    const target = event.currentTarget as HTMLAnchorElement;
    const href = target.getAttribute('href');

    if (!href || href === '#') {
      event.preventDefault();

      const form = this.querySelector('form');
      const value = target.dataset.value || '';

      if (this.elements.hiddenInput !== null) {
        this.elements.hiddenInput.value = value;
      }

      if (form) form.submit();
    }
  }
}
