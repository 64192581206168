interface DomElements {
  container: HTMLElement;
  decrementButton?: HTMLElement | null;
  incrementButton?: HTMLElement | null;
  quantitySelector?: HTMLInputElement | null;
}
export class CartItemQuantity {
  dom: DomElements;
  originalMax: number;

  constructor(container: HTMLElement) {
    this.dom = {
      container,
      decrementButton: container.querySelector<HTMLElement>('#decrement-button'),
      incrementButton: container.querySelector<HTMLElement>('#increment-button'),
      quantitySelector: container.querySelector<HTMLInputElement>("[data-element='quantity-selector']"),
    };

    this.originalMax = Number(this.dom.quantitySelector?.getAttribute('max'));

    this._adjustMaxValueToStep();
    this._bindEvents();
  }

  _bindEvents() {
    this.dom.incrementButton?.addEventListener('click', () => this._incrementQuantity());
    this.dom.decrementButton?.addEventListener('click', () => this._decrementQuantity());
    this.dom.quantitySelector?.addEventListener('change', () => this._adjustQuantityToStep());
  }

  _incrementQuantity() {
    const currentValue = Number(this.dom.quantitySelector?.value);
    const maximumValue = Number(this.dom.quantitySelector?.getAttribute('max'));
    const stepValue = Number(this.dom.quantitySelector?.getAttribute('step') || 1);
    const newValue = currentValue + stepValue;

    if (newValue <= maximumValue) {
      this.dom.quantitySelector!.value = newValue.toString();
    } else {
      this.dom.quantitySelector!.value = maximumValue.toString();
    }
    this.dom.quantitySelector!.dispatchEvent(new Event('change'));
  }

  _decrementQuantity() {
    const currentValue = Number(this.dom.quantitySelector?.value);
    const minimumValue = Number(this.dom.quantitySelector?.getAttribute('min') || 0);
    const stepValue = Number(this.dom.quantitySelector?.getAttribute('step') || 1);
    const newValue = currentValue - stepValue;

    if (newValue >= minimumValue) {
      this.dom.quantitySelector!.value = newValue.toString();
    } else {
      this.dom.quantitySelector!.value = minimumValue.toString();
    }
    this.dom.quantitySelector!.dispatchEvent(new Event('change'));
  }

  _adjustQuantityToStep() {
    const currentValue = Number(this.dom.quantitySelector?.value);
    const stepValue = Number(this.dom.quantitySelector?.getAttribute('step') || 1);
    const minimumValue = Number(this.dom.quantitySelector?.getAttribute('min') || 0);
    const maximumValue = Number(this.dom.quantitySelector?.getAttribute('max'));

    let newValue = currentValue;

    const remainder = (currentValue - minimumValue) % stepValue;
    if (remainder !== 0) {
      if (remainder < stepValue / 2) {
        newValue = currentValue - remainder;
      } else {
        newValue = currentValue + (stepValue - remainder);
      }
      newValue = Math.min(newValue, maximumValue);
      this.dom.quantitySelector!.value = newValue.toString();
    }
    this._adjustMaxValueToStep();
  }
  _adjustMaxValueToStep() {
    const stepValue = Number(this.dom.quantitySelector?.getAttribute('step') || 1);
    const minimumValue = Number(this.dom.quantitySelector?.getAttribute('min') || 0);

    let maxStepValue = this.originalMax - ((this.originalMax - minimumValue) % stepValue);
    if (maxStepValue === this.originalMax && (this.originalMax - minimumValue) % stepValue !== 0) {
      maxStepValue -= stepValue;
    }

    this.dom.quantitySelector!.setAttribute('max', maxStepValue.toString());
  }
}
