import {Disclosure} from '@/utils/disclosure';
import {twClassMerge} from '../utils/twClassMerge';

interface SelectorOptions {
  container: HTMLElement;
  toggleBtn: HTMLElement;
  optionsListItems: NodeListOf<HTMLElement>;
  buttonText: Element | null;
  dropdownElement: Element | null;
}

export class Selector {
  dom: SelectorOptions;
  selector: Disclosure;

  constructor(container: HTMLElement) {
    this.dom = {
      container: container,
      toggleBtn: container.querySelector('[data-element=disclosure-button]')!,
      optionsListItems: container.querySelectorAll('li'),
      buttonText: container.querySelector(`[data-element=disclosure-button] span`),
      dropdownElement: container.querySelector('[data-dropdown-position]')!,
    };

    const dropdownPosition = this.dom.dropdownElement?.getAttribute('data-dropdown-position')?.split(' ');
    const dropdownPlacement = dropdownPosition ? dropdownPosition[0] : '';

    this.selector = new Disclosure({
      domNode: this.dom.container,
      callback: (isOpen, disclosureEl, button) => {
        button[0]?.setAttribute('aria-expanded', isOpen.toString());
        twClassMerge(disclosureEl, isOpen ? 'opacity-100' : 'opacity-0 invisible');
        if (dropdownPlacement !== 'top') {
          twClassMerge(disclosureEl, isOpen ? 'translate-y-1' : '-translate-y-1');
        } else {
          twClassMerge(disclosureEl, isOpen ? '-translate-y-1' : 'translate-y-1');
        }
      },
    });

    this.init();
  }

  init() {
    for (let option of this.dom.optionsListItems) {
      option.addEventListener('click', (event: Event) => this.handleClick(event), true);
    }
  }

  handleClick(event: Event) {
    let clickedElements = event.composedPath() as HTMLElement[];

    for (let el of clickedElements) {
      if (this.isCheckboxClicked(el)) {
        return;
      }
      if (this.isLabelForCheckboxClicked(el)) {
        return;
      }
    }

    this.selector.close();
  }

  isCheckboxClicked(el: HTMLElement): boolean {
    return el instanceof HTMLInputElement && el.type === 'checkbox';
  }

  isLabelForCheckboxClicked(el: HTMLElement): boolean {
    return el instanceof HTMLLabelElement && (el.control as HTMLInputElement)?.type === 'checkbox';
  }
}
