// Swiper
import '../styles/swiper.css';
import '../styles/pagination.css';
import '../styles/effect-fade.css';

// Apps
import '../styles/app-klaviyo-reviews.css';

// Base styling
import 'unfonts.css';
import './tailwind.css';
import '../styles/gradient.css';
import '../styles/navigation.css';
import '../styles/rich-text.css';

// UI components
import {Accordion} from '@/ui/accordion';
import {asyncBootstrapper, bootstrapper} from '@/utils/bootstrapper';
import {Navigation} from '@/ui/navigation';
import {Drawer} from '@/ui/drawer';
import {Header} from '@/ui/header';
import {Search} from '@/ui/search';
import {Selector} from '@/ui/selector';
import {ProductRecommendations} from '@/ui/product-recommendations';
import {CartRecommendations} from '@/ui/cart-recommendations';
import {ProductForm} from '@/ui/productForm';
import {CartDialog} from '@/ui/cartDialog';
import {CartButton} from '@/ui/cartBtn';
import {CartButtons} from '@/ui/cartButtons';
import {FullscreenSlide} from '@/ui/fullscreen-slide';
import {Input} from '@/ui/input';
import {CartItemQuantity} from '@/ui/cart-item-quantity';
import {AddressDetails} from '@/ui/address-details';
import {ProductInformation} from '@/ui/product-information';
import {GiftCard} from '@/ui/gift-card';
import {RecipientForm} from '@/ui/recipient-form';
import {Lightbox} from '@/ui/lightbox';

// Web components
import {ShoppingCart} from '@/web-components/shopping-cart';
import {ProductListing} from '@/web-components/product-listing';
import {FacetFilters} from '@/web-components/facet-filters';
import {SortBy} from '@/web-components/sort-by';
import {LocalizationForm} from '@/web-components/localization-form';
import {MainLogin} from '@/web-components/main-login';
import {Modal} from '@/web-components/modal-element';

const modules = {
  accordion: Accordion,
  navigation: Navigation,
  drawer: Drawer,
  header: Header,
  search: Search,
  selector: Selector,
  'product-recommendations': ProductRecommendations,
  'cart-recommendations': CartRecommendations,
  'product-form': ProductForm,
  'cart-dialog': CartDialog,
  'cart-btn': CartButton,
  'cart-buttons': CartButtons,
  'fullscreen-slide': FullscreenSlide,
  input: Input,
  'number-input': CartItemQuantity,
  'address-details': AddressDetails,
  'product-information': ProductInformation,
  'gift-cart': GiftCard,
  'recipient-form': RecipientForm,
  'lightbox': Lightbox,
};

const asyncModules = {
  carousel: () => import('@/ui/carousel').then((mod) => mod.Carousel),
};

const init = () => {
  bootstrapper(modules);
  asyncBootstrapper(asyncModules);

  // Define web components
  customElements.define('shopping-cart', ShoppingCart);
  customElements.define('product-listing', ProductListing);
  customElements.define('sort-by', SortBy);
  customElements.define('facet-filters', FacetFilters);
  customElements.define('localization-form', LocalizationForm);
  customElements.define('main-login', MainLogin);
  customElements.define('modal-element', Modal);
};

// @ts-ignore
// Refreshes javascript in editor mode (Shopify)
if (Shopify && Shopify.designMode) {
  document.addEventListener('DOMContentLoaded', () => init());
  document.addEventListener('shopify:section:load', () => init());
  document.addEventListener('shopify:section:reorder', () => init());
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}
