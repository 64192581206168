/**
 * Parses the given HTML string and returns the inner HTML of the first element with the class "shopify-section".
 * If no element is found, an empty string is returned.
 * 
 * @param htmlAsString - The HTML string to parse.
 * @returns The inner HTML of the first element with the class "shopify-section", or an empty string.
 */
export const getFetchedShopifySection = (htmlAsString: string) => {
  return new DOMParser().parseFromString(htmlAsString, 'text/html').querySelector('.shopify-section')?.innerHTML ?? '';
};
