import {twClassMerge} from '../utils/twClassMerge';

interface ValidationResult {
  isValid: boolean;
}

export class RecipientForm {
  dom;
  emailField: HTMLInputElement | null;
  emailErrorContainer: HTMLElement | null;

  constructor(domNode: HTMLElement) {
    this.dom = {
      container: domNode,
      checkbox: domNode.querySelector('.recipient-checkbox'),
      recipientFields: domNode.querySelector('.recipient-fields'),
    };

    this.emailField = domNode.querySelector('input[type="email"]');
    this.emailErrorContainer = domNode.querySelector('[id^="RecipientForm-email-error-"]');

    if (this.emailField) {
      this.emailField.addEventListener('input', () => {
        this.validateEmail();
      });
    }

    if (this.dom.checkbox && this.dom.recipientFields) {
      this.dom.checkbox.addEventListener('change', () => {
        this.toggleRecipientFields();
      });

      this.toggleRecipientFields();
    }
  }

  validateEmail(): void {
    if (!this.emailField || !this.emailErrorContainer) return;

    const result = this.emailValidator(this.emailField.value);

    twClassMerge(this.emailErrorContainer as HTMLElement, result.isValid ? 'hidden' : 'flex');
  }

  emailValidator(email: string): ValidationResult {
    // Simple regex for basic email validation
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(regex)) {
      return {isValid: true};
    } else {
      return {isValid: false};
    }
  }

  toggleRecipientFields() {
    const isVisible = (this.dom.checkbox as HTMLInputElement).checked;

    twClassMerge(this.dom.recipientFields as HTMLElement, isVisible ? 'block' : 'hidden');
  }
}
