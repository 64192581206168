import {Disclosure} from '@/utils/disclosure';
import {twClassMerge} from '@/utils/twClassMerge';

export class CartDialog {
  drawer;
  triggerElement: HTMLButtonElement | HTMLAnchorElement | HTMLInputElement | undefined;

  constructor(container: HTMLElement) {
    this.drawer = new Disclosure({
      domNode: container,
      callback: (isOpen, disclosureEl, btns) => {
        twClassMerge(disclosureEl, isOpen ? 'translate-x-0' : 'translate-x-full');
        if (isOpen) {
          btns[0]?.focus();
        }
      },
      onTransitionEnd: (event, isOpen) => {
        if (!isOpen && event.propertyName === 'transform') {
          this.triggerElement?.focus();
        }
      },
    });

    window.addEventListener('cart:toggle', (event) => {
      this.triggerElement = event.detail?.element;
      // setTimeout ensures that the callback is only run once on this event. Hack-ish, I know.
      setTimeout(() => {
        this.drawer.toggle();
      }, 0);
    });
  }
}
