import {Disclosure} from '../utils/disclosure';
import {twClassMerge} from '../utils/twClassMerge';

interface CartToggleEventDetail {
  element: HTMLButtonElement | HTMLAnchorElement | HTMLInputElement;
}

export class Drawer {
  private drawer: Disclosure;
  private triggerElements: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement> = [];

  constructor(container: HTMLElement) {
    this.drawer = new Disclosure({
      domNode: container,
      callback: this.disclosureCallback.bind(this),
      onTransitionEnd: this.onTransitionEndCallback.bind(this),
    });

    window.addEventListener('drawer:toggle', this.drawerToggleListener.bind(this) as EventListener);

    const closeButton: HTMLButtonElement | null = container.querySelector('[data-element="disclosure-button"]');
    if (closeButton) {
      closeButton.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.closeDrawer();
      });
    }
  }

  private disclosureCallback(isOpen: boolean, disclosureEl: HTMLElement, buttons: HTMLElement[]) {
    // TODO: Delete this when Drawer is created as a reuseable component
    const productInformation = document.querySelector("product-information")
    twClassMerge(productInformation as HTMLElement, isOpen ? 'z-30' : 'z-0');
    
    twClassMerge(disclosureEl, isOpen ? 'translate-x-0' : 'translate-x-full');
    if (isOpen) {
      buttons[0]?.focus();
    }
  }

  private onTransitionEndCallback(event: TransitionEvent, isOpen: boolean) {
    // Focus the triggering elements only when the drawer is closing.
    if (!isOpen && event.propertyName === 'transform') {
      this.triggerElements.forEach((triggerEl) => triggerEl.focus());
    }
  }

  private drawerToggleListener(event: CustomEvent<CartToggleEventDetail>) {
    const triggerElement = event.detail.element;
    this.triggerElements.push(triggerElement);
    this.drawer.toggle();
  }

  public closeDrawer() {
    this.drawer.close();
    this.triggerElements.forEach((triggerEl) => triggerEl.blur());
    this.triggerElements = [];
  }
}
