declare var QRCode: any;

export class GiftCard {
  private dom: {
    container: HTMLElement;
    toggleBtn: HTMLElement;
  };

  private readonly qrImageAlt: string;

  constructor(domNode: HTMLElement) {
    this.dom = {
      container: domNode,
      toggleBtn: domNode.querySelector<HTMLElement>('[data-element=toggle-button]')!,
    };

    this.qrImageAlt = JSON.parse((document.getElementById('qrImageAltJson') as HTMLInputElement)?.value || '""');

    document.addEventListener('DOMContentLoaded', () => {
      this.initializeQRCode();
    });

    this.attachCopyListener();
  }

  private initializeQRCode(): void {
    const qrCodeElement = this.dom.container.querySelector<HTMLElement>('.gift-card__qr-code');
    if (!qrCodeElement) {
      console.error('QR Code element not found');
      return;
    }

    new QRCode(qrCodeElement, {
      text: qrCodeElement.dataset.identifier ?? '',
      width: 72,
      height: 72,
      imageAltText: this.qrImageAlt,
    });
  }

  private attachCopyListener(): void {
    const copyButton = this.dom.container.querySelector<HTMLButtonElement>('.gift-card__copy-button');
    const giftCardCode = this.dom.container.querySelector<HTMLElement>('#gift-card-code');
    const copySuccessContainer = this.dom.container.querySelector('.gift-card__copy-success');
    const template = document.getElementsByTagName('template')[0]?.content.cloneNode(true) as Node;
    let isMessageDisplayed = false;

    if (!copyButton || !giftCardCode || !copySuccessContainer) {
      console.error('One or more elements for copy functionality not found');
      return;
    }

    copyButton.addEventListener('click', async () => {
      try {
        await navigator.clipboard.writeText(giftCardCode.innerText);
        if (!isMessageDisplayed) {
          copySuccessContainer.appendChild(template);
          isMessageDisplayed = true;
        }
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    });
  }
}