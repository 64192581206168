import {spinner} from '@/js-html/spinner';
import {dispatchCustomEvent} from '@/utils/dispatchCustomEvent';
import {updateCartDOM} from '@/utils/updateCartDOM';

interface ShopifyWindow extends Window {
  Shopify: {
    routes: {
      root: string;
    };
  };
}

declare const window: ShopifyWindow;

export class ProductForm {
  container: HTMLElement;
  form: HTMLFormElement;
  submitBtn: HTMLButtonElement;
  originalButtonContent: string;

  constructor(container: HTMLElement) {
    this.container = container;
    this.form = container.querySelector<HTMLFormElement>('form')!;
    this.submitBtn = this.form.querySelector<HTMLButtonElement>('button[type=submit]')!;
    this.originalButtonContent = this.submitBtn.innerHTML;

    this.form.addEventListener('submit', this.onSubmitHandler);
  }

  onSubmitHandler = (event: SubmitEvent) => {
    event.preventDefault();
    this.addToCart(this.submitBtn);
  };

  addToCart = async (element: HTMLElement | null) => {
    const config: RequestInit = {
      method: 'POST',
      headers: {
        Accept: 'application/javascript',
      },
    };

    // Prepare form data
    const formData = new FormData(this.form);
    formData.append('sections', 'shopping-cart,cart-amount,checkout-buttons,cart-recommendations');
    formData.append('sections_url', window.location.pathname);
    config.body = formData;

    // Add spinner + disabled
    this.setButtonLoading(true);

    // Fetch data and update DOM
    try {
      const response = await fetch(this.container.dataset.addToCartUrl! + ".js", config);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      updateCartDOM(data);
    } catch (error) {
      console.error('Error adding to cart:', error);
    } finally {
      // Remove spinner + disabled
      this.setButtonLoading(false);
    }
    dispatchCustomEvent('cart:toggle', {
      detail: {element},
    });
  };

  setButtonLoading(isLoading: boolean) {
    // Re-query the button in case it has been replaced
    this.submitBtn = this.form.querySelector<HTMLButtonElement>('button[type=submit]')!;

    if (isLoading) {
      this.submitBtn.innerHTML = spinner();
      this.submitBtn.setAttribute('disabled', '');
    } else {
      this.submitBtn.innerHTML = this.originalButtonContent;
      this.submitBtn.removeAttribute('disabled');
    }
  }
}