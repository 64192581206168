export class ProductInformation {
  private container: HTMLElement;

  constructor(container: HTMLElement) {
    this.container = container;
    this.init();
  }

  init() {
    window.addEventListener('load', () => {
      this.handleStickyBehavior();
    });
  }

  handleStickyBehavior() {
    const buffer = 200;

    if (this.container.offsetHeight < window.innerHeight - buffer) {
      this.container.classList.add('md:sticky', 'md:top-32');
    }
  }
}
