export class FullscreenSlide {
  constructor(container: HTMLDivElement) {
    let card = container.querySelector('fullscreen-slide-card') as HTMLDivElement;

    const containerSensitivity = 0.4; // Adjust the sensitivity as needed
    const cardSensitivity = 0.2; // Adjust the sensitivity as needed

    function moveVertically(movement: number) {
      container.style.setProperty('--fullscreen-slide-translate-y', `${movement}px`);
      container.style.setProperty('--fullscreen-card-translate-x', `${movement}px`);
    }

    function moveHorizontally(movement: number) {
      container.style.setProperty('--fullscreen-slide-translate-x', `${movement}px`);
      container.style.setProperty('--fullscreen-card-translate-y', `${movement}px`);
    }

    // Function to handle mouse and touch movement
    function handleMovement(event: MouseEvent | TouchEvent) {
      const containerRect = container.getBoundingClientRect();
      const mouseX = event instanceof MouseEvent ? event.clientX : event!.touches[0]!.clientX;
      const mouseY = event instanceof MouseEvent ? event.clientY : event!.touches[0]!.clientY;

      const offsetX = mouseX - containerRect.left;
      const offsetY = mouseY - containerRect.top;

      // Calculate the parallax effect
      const parallaxX = (offsetX - containerRect.width / 2) * containerSensitivity;
      const parallaxY = (offsetY - containerRect.height / 2) * containerSensitivity;

      // Limit the card's movement to a maximum of 10 pixels
      const clampedParallaxX = Math.min(Math.max(parallaxX, -15), 15);
      const clampedParallaxY = Math.min(Math.max(parallaxY, -15), 15);

      moveHorizontally(clampedParallaxX);
      moveVertically(clampedParallaxY);
    }

    container.addEventListener('mousemove', handleMovement);
    container.addEventListener('touchmove', handleMovement);
  }
}
