import {asyncBootstrapper} from '@/utils/bootstrapper';

export class CartRecommendations {
  cartRecommendationsSection;
  observer;
  constructor() {
    this.cartRecommendationsSection = document.querySelector<HTMLElement>('.cart-recommendations')!;
    this.observer = new IntersectionObserver(this.handleIntersection, {rootMargin: '0px 0px 200px 0px'});

    this.observer.observe(this.cartRecommendationsSection);
  }
  handleIntersection: IntersectionObserverCallback = (entries, observer) => {
    if (!entries[0]?.isIntersecting) return;

    observer.unobserve(this.cartRecommendationsSection);

    const url = this.cartRecommendationsSection.dataset.url;

    if (url) {
      this.fetchProducts(url).then(() => {
        asyncBootstrapper({
          'carousel': () => import('@/ui/carousel').then((mod) => mod.Carousel),
        });
      });
    }
  };
  fetchProducts = async (url: string) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const html = document.createElement('div');
      html.innerHTML = text;
      const recommendations = html.querySelector('.cart-recommendations');
      if (recommendations && recommendations.innerHTML.trim().length) {
        this.cartRecommendationsSection.innerHTML = recommendations.innerHTML;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}
