export class Lightbox {
  private dom: {
    container: HTMLElement;
    lightbox: HTMLElement | null;
    lightboxWrapper: HTMLElement | null;
    closeButton: HTMLElement | null;
    imageContainers: NodeListOf<Element>;
  };

  constructor(container: HTMLElement) {
    this.dom = {
      container,
      lightbox: document.getElementById('lightbox'),
      lightboxWrapper: document.getElementById('lightbox-wrapper'),
      closeButton: document.getElementById('close-lightbox'),
      imageContainers: document.querySelectorAll('.image-container'),
    };

    this.init();
  }

  private init(): void {
    if (!this.dom.lightbox || !this.dom.lightboxWrapper || !this.dom.closeButton) {
      console.error('Lightbox elements not found');
      return;
    }

    this.bindEvents();
  }

  private bindEvents(): void {
    this.dom.imageContainers.forEach((container) => {
      container.addEventListener('click', (e) => this.openLightbox(e));
    });

    this.dom.closeButton?.addEventListener('click', () => this.closeLightbox());
    this.dom.lightbox?.addEventListener('click', (e) => {
      if (e.target === this.dom.lightbox) this.closeLightbox();
    });

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') this.closeLightbox();
    });
  }

  private openLightbox(e: Event): void {
    e.preventDefault();
    if (!this.dom.lightbox) return;

    this.dom.lightbox.classList.remove('hidden');
    this.dom.lightbox.classList.add('flex');
    document.body.style.overflow = 'hidden';
  }

  private closeLightbox(): void {
    if (!this.dom.lightbox) return;

    this.dom.lightbox.classList.add('hidden');
    this.dom.lightbox.classList.remove('flex');
    document.body.style.overflow = '';
  }

  public destroy(): void {
    this.dom.imageContainers.forEach((container) => {
      container.removeEventListener('click', (e) => this.openLightbox(e));
    });

    this.dom.closeButton?.removeEventListener('click', () => this.closeLightbox());
    this.dom.lightbox?.removeEventListener('click', (e) => {
      if (e.target === this.dom.lightbox) this.closeLightbox();
    });

    document.removeEventListener('keydown', (e) => {
      if (e.key === 'Escape') this.closeLightbox();
    });
  }
}
