export class AddressDetails {
  dom: {
    container: HTMLElement;
    deleteButtons?: NodeListOf<Element>;
  };

  constructor(container: HTMLElement) {
    this.dom = {
      container,
    };
    this.initialize();
  }

  initialize(): void {
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.dom.deleteButtons = this.dom.container.querySelectorAll('[data-target]');

    this.dom.deleteButtons.forEach((button) => {
      button.addEventListener('click', (e) => this.handleDeleteButtonClick(e));
    });
  }

handleDeleteButtonClick(event: Event): void {
  const target = event.currentTarget as HTMLButtonElement;
  const addressUrl = target.getAttribute('data-target');
  const confirmMessage = target.getAttribute('data-confirm-message')!;

  if (!addressUrl) {
    console.error('Address URL is missing');
    return;
  }

  if (!confirm(confirmMessage)) {
    return;
  }

  setTimeout(() => this.deleteAddress(target, addressUrl), 0);
}

async deleteAddress(target: HTMLButtonElement, addressUrl: string): Promise<void> {
  try {
    const response = await fetch(addressUrl, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `form_type=customer_address&id=${target.dataset.addressId}&_method=delete`,
      credentials: 'same-origin',
    });

    if (!response.ok) {
      throw new Error('Failed to delete address');
    }

    const addressElement = target.closest('[data-address]');
    if (addressElement) {
      addressElement.remove();
    }
    alert('Address deleted successfully');
  } catch (error) {
    console.error('Error deleting address:', error);
  }
}
}
