export class Modal extends HTMLElement {
  modal: HTMLElement;
  modalBackdrop: HTMLElement;

  constructor() {
    super();
    const modal = document.getElementById('modal');
    const modalBackdrop = document.getElementById('modalBackdrop');

    if (!(modal && modalBackdrop)) {
      throw new Error('Modal or modalBackdrop not found');
    }

    this.modal = modal as HTMLElement;
    this.modalBackdrop = modalBackdrop as HTMLElement;

    this.initializeModal();
  }

  initializeModal(): void {
    const triggers = document.querySelectorAll('.modal-trigger');

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        const target = event.currentTarget as HTMLElement;
        const contentId = target.dataset.modalContent!;
        const contentElement = document.getElementById(contentId);

        if (contentElement && this.modal && this.modalBackdrop) {
          const modalBody = this.modal.querySelector('#modalBody');

          if (modalBody) {
            modalBody.innerHTML = contentElement.innerHTML;

            this.modal.classList.remove('hidden');
            this.modalBackdrop.classList.remove('hidden');
            // Optionally add title and other dynamic content handling here
          }
        }
      });
    });

    this.setupCloseEvents();
  }

  setupCloseEvents(): void {
    this.modal.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const isCloseButton = eventTarget.closest('.modal-close') !== null;
      const isOutsideModalContent = eventTarget.closest('#modal-content') === null;

      if (isCloseButton || isOutsideModalContent) {
        this.closeModal();
      }
    });

    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    });
  }

  closeModal(): void {
    this.modal.classList.add('hidden');
    this.modalBackdrop.classList.add('hidden');
  }
}