type CustomEventName = 'cart:toggle';

const toggleCart = new CustomEvent('cart:toggle' as CustomEventName);

export const dispatchCustomEvent = (eventName: CustomEventName, options?: CustomEventInit): void => {
  if (eventName === 'cart:toggle') {
    if (options) {
      const cartToggleEventWithOptions = new CustomEvent('cart:toggle', options);
      window.dispatchEvent(cartToggleEventWithOptions);
      return;
    }
    window.dispatchEvent(toggleCart);
  }
};
