export class MainLogin extends HTMLElement {
  private loginForm: HTMLElement;
  private recoverForm: HTMLElement;

  constructor() {
    super();
    this.loginForm = document.getElementById('login-form') as HTMLElement;
    this.recoverForm = document.getElementById('recover-password-form') as HTMLElement;

    this.attachEventHandlers();
  }

  private attachEventHandlers(): void {
    this.querySelectorAll('[data-action="toggle-recover-password"]').forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggleRecoverPasswordForm();
      });
    });
  }

  toggleRecoverPasswordForm(): void {
    if (this.recoverForm.classList.contains('hidden')) {
      this.recoverForm.classList.remove('hidden');
      this.loginForm.classList.add('hidden');
    } else {
      this.recoverForm.classList.add('hidden');
      this.loginForm.classList.remove('hidden');
    }
  }
}
