import {dispatchCustomEvent} from '@/utils/dispatchCustomEvent';

export class Header {
  private lastScrollTop = 0;
  private readonly delta = 200;
  private readonly hiddenHeaderPosition = '-translate-y-full';
  private sticky = this.container.querySelector('header-content');
  cartBtn;

  constructor(private readonly container: HTMLElement) {
    this.cartBtn = container.querySelector<HTMLButtonElement>('[data-element=cart-btn]');

    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.cartBtn?.addEventListener('click', (event) => {
      dispatchCustomEvent('cart:toggle', {
        detail: {
          element: event.currentTarget,
        },
      });
    });
  }

  private handleScroll() {
    const st = window.scrollY || document.documentElement.scrollTop;

    if (st > this.lastScrollTop) {
      if (Math.abs(this.lastScrollTop - st) <= this.delta) return;
      this.toggleHeaderClass(false);
    } else {
      this.toggleHeaderClass(true);
    }

    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  private toggleHeaderClass(showHeader: boolean) {
    const {sticky, container, hiddenHeaderPosition} = this;

    sticky?.classList.remove(showHeader ? hiddenHeaderPosition : 'translate-y-0');
    sticky?.classList.add(showHeader ? 'translate-y-0' : hiddenHeaderPosition);

    container.classList.toggle('pointer-events-none', !showHeader);
  }
}
