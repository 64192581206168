import {twMerge} from 'tailwind-merge';

interface TwClassMerge {
  (element: HTMLElement | null, classes: string, updateDom?: boolean): string;
}

export const twClassMerge: TwClassMerge = (element, classes, updateDom = true) => {
  if (!element) {
    return '';
  }

  const mergedClasses = twMerge(Array.from(element.classList).join(' '), classes);

  if (updateDom) {
    element.setAttribute('class', mergedClasses);
  }

  return mergedClasses;
};
