export class CartButtons {
  constructor(container: HTMLElement) {
    const termsCheckbox = container.querySelector<HTMLInputElement>('#terms');
    const checkoutButton = container.querySelector<HTMLButtonElement>('#checkout-btn');
    const termsError = container.querySelector<HTMLElement>('#terms-error');

    checkoutButton?.addEventListener('click', function (event) {
      if (termsCheckbox && !termsCheckbox.checked) {
        event.preventDefault();
        termsError?.classList.remove('hidden');
        termsError?.classList.add('animate-singleJump');

        // Remove the animation class after it completes to reset the state
        const removeAnimation = () => {
          termsError?.classList.remove('animate-singleJump');
          termsError?.removeEventListener('animationend', removeAnimation);
        };

        termsError?.addEventListener('animationend', removeAnimation);
      }
    });

    // Also remove the error message on load
    if (termsError) termsError.classList.add('hidden');
  }
}