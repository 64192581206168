import {Disclosure} from '@/utils/disclosure';
import {twClassMerge} from '@/utils/twClassMerge';
import {debounce} from '@/utils/debounce';

// https://shopify.dev/docs/themes/navigation-search/search/predictive-search#the-javascript-function
export class Search {
  dom;
  dialog;

  constructor(container: HTMLElement) {
    this.dom = {
      container,
      input: container.querySelector<HTMLInputElement>('[data-element=search-input]')!,
      predictiveSearchResults: container.querySelector<HTMLElement>('[data-element=predictive-search]')!,
      defaultResults: container.querySelector<HTMLElement>('[data-element=default-results]')!,
    };

    this.dialog = new Disclosure({
      domNode: container,
      callback: (isOpen, disclosureEl, btns) => {
        twClassMerge(disclosureEl, isOpen ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0');

        // Set focus
        if (!isOpen) {
          btns[0]?.focus();
        }
      },
      onTransitionEnd: (event, isOpen) => {
        if (event.propertyName === 'opacity' && isOpen) {
          this.dom.input?.focus();
        }
      },
    });

    this.dom.input.addEventListener(
      'input',
      debounce(() => {
        this.onChange();
      }, 300)
    );
  }

  onChange = () => {
    const searchTerm = this.dom.input.value.trim();

    if (!searchTerm.length) {
      this.close();
      return;
    }
    this.getSearchResults(searchTerm);
  };

  getSearchResults = async (searchTerm: string) => {
    try {
      const response = await fetch(
        `${this.dom.container.dataset.searchUrl}?q=${searchTerm}&section_id=predictive-search`
      );

      if (!response.ok) {
        var error = new Error(`${response.status}`);
        this.close();
        throw error;
      }
      const text = await response.text();

      const resultsMarkup = new DOMParser()
        .parseFromString(text, 'text/html')
        .querySelector('#shopify-section-predictive-search')?.innerHTML;
      this.dom.predictiveSearchResults.innerHTML = resultsMarkup as string;
      this.open();
    } catch (error) {
      this.close();
      throw error;
    }
  };

  open = () => {
    twClassMerge(this.dom.defaultResults, 'hidden');
    twClassMerge(this.dom.predictiveSearchResults, 'block');
  };

  close = () => {
    twClassMerge(this.dom.defaultResults, 'block');
    twClassMerge(this.dom.predictiveSearchResults, 'hidden');
  };
}
