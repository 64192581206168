import {getFetchedShopifySection} from '@/utils/getFetchedShopifySection';
import {CartItemQuantity} from '@/ui/cart-item-quantity';
import {CartRecommendations} from '@/ui/cart-recommendations';

const updateDom = async (htmlAsString: string, el: HTMLElement): Promise<void> => {
  const html = getFetchedShopifySection(htmlAsString);
  if (el.previousElementSibling) {
    el.insertAdjacentHTML('afterend', html);
    el.remove();
  } else if (el.nextElementSibling) {
    el.insertAdjacentHTML('beforebegin', html);
    el.remove();
  } else if (el.parentElement) {
    el.parentElement.innerHTML = html;
    console.log("🚀 ~ updateDom ~ html:", html)
  }
};

const initializeModule = <T>(selector: string, Constructor: new (el: HTMLElement) => T): void => {
  document.querySelectorAll<HTMLElement>(selector).forEach((el) => new Constructor(el));
};

export const updateCartDOM = async (data: {sections: Record<string, string>}, focusElId?: string): Promise<void> => {
  const elements = [
    'shopping-cart',
    'cart-amount',
    'checkout-buttons',
    'cart-recommendations',
  ];

  for (const selector of elements) {
    const el = document.querySelector<HTMLElement>(selector);
    if (el && data.sections?.[selector]) {
      await updateDom(data.sections[selector]!, el);
    }
  }

  if (data.sections?.['shopping-cart']) {
    initializeModule('[data-module="number-input"]', CartItemQuantity);
  }

  if (data.sections?.['cart-recommendations']) {
    initializeModule('[data-module="cart-recommendations"]', CartRecommendations);
  }

  if (focusElId) {
    document.querySelector<HTMLElement>(focusElId)?.focus();
  }
};