import {getFetchedShopifySectionById} from '@/utils/getFetchedShopifySectionById';
import {debounce} from '@/utils/debounce';

export class FacetFilters extends HTMLElement {
  dom;
  checkboxDataMap = new Map();
  debouncedFetchResults;

  constructor() {
    super();
    this.dom = {
      containers: Array.from(this.querySelectorAll('[data-module="selector"]'))!,
      collectionWithFacets: document.getElementById('collection-with-facets')!,
      urlParams: new URLSearchParams(window.location.search),
    };

    this.debouncedFetchResults = debounce(this.fetchResults, 500);

    const mo = new MutationObserver(() => {
      this.dom.containers.forEach((container) => {
        container.removeEventListener('click', this.updateFilterState);
      });
      this.init();
    });

    const facetGroups = document.querySelectorAll('[id^="facet-group-"]');

    facetGroups.forEach((group) => {
      mo.observe(group, {
        childList: true,
      });
    });

    this.init();
  }

  init = () => {
    this.dom.containers.forEach((container) => {
      const checkboxes = Array.from(container.querySelectorAll('li input[type="checkbox"]')) as HTMLInputElement[];
      const paramName = container.getAttribute('data-param-name')!;

      checkboxes.forEach((checkbox) => {
        this.checkboxDataMap.set(checkbox, {paramName, filterValue: checkbox.value});
      });

      container.addEventListener('click', this.updateFilterState);
    });
  };

  updateFilterState = (event: Event) => {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.tagName === 'INPUT' && checkbox.type === 'checkbox') {
      const {paramName, filterValue} = this.checkboxDataMap.get(checkbox);
      this.dom.urlParams = new URLSearchParams(window.location.search);

      if (checkbox.checked) {
        this.dom.urlParams.append(paramName, filterValue);
      } else {
        const allValues = this.dom.urlParams.getAll(paramName);
        const newValues = allValues.filter((val) => val !== filterValue);
        this.dom.urlParams.delete(paramName);
        newValues.forEach((val) => this.dom.urlParams.append(paramName, val));
      }

      window.history.replaceState(
        {searchParams: this.dom.urlParams.toString()},
        '',
        `${window.location.pathname}?${this.dom.urlParams.toString()}`
      );

      this.debouncedFetchResults();
    }
  };

  fetchResults = () => {
    const search = this.dom.urlParams.toString() ? `${this.dom.urlParams.toString()}` : '';
    const sectionId = this.dataset.sectionId;
    const url = `${window.location.origin}${window.location.pathname}?section_id=${sectionId}&${search}`;

    fetch(url).then(async (response) => {
      const text = await response.text();

      this.dom.collectionWithFacets.innerHTML = getFetchedShopifySectionById(text, this.dom.collectionWithFacets.id);

      const productCounts = document.querySelectorAll('[id^="product-count-"]');
      productCounts.forEach((productCount) => {
        const uniqueId = productCount.id;
        productCount.innerHTML = getFetchedShopifySectionById(text, uniqueId);
      });

      const facetGroups = document.querySelectorAll('[id^="facet-group-"]');
      facetGroups.forEach((facetGroup) => {
        const uniqueId = facetGroup.id;
        facetGroup.innerHTML = getFetchedShopifySectionById(text, uniqueId);
      });
    });
  };
}
