/**
 * Debounces a function, ensuring it is only called after a specified wait time has passed since the last invocation.
 * @param fn The function to debounce.
 * @param wait The wait time in milliseconds.
 * @returns A debounced version of the function.
 */
export const debounce = (fn: Function, wait: number) => {
  let t: number;
  return (...args: any[]) => {
    clearTimeout(t);
    t = window.setTimeout(() => fn.apply(this, args), wait);
  };
};
