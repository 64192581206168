type BootstrapperParams = Record<string, any>;
type AsyncBootstrapperParams = Record<string, () => Promise<any>>;

export const bootstrapper = (modules: BootstrapperParams, container: Document | HTMLElement = document) => {
  const dataModuleEls = Array.from(container.querySelectorAll<HTMLElement>('[data-module]'))!;

  return Object.entries(modules)
    .map(([name, Module]) => {
      // Find dom element
      const domNodes = dataModuleEls.filter((el) => {
        return el.dataset.module?.split(' ').includes(name);
      });

      const modulesInstances = domNodes.map((domNode) => {
        return new Module(domNode);
      });

      return {[name]: modulesInstances};
    })
    .reduce((acc, val) => {
      return {...acc, ...val};
    }, {});
};

export const asyncBootstrapper = (modules: AsyncBootstrapperParams, container: Document | HTMLElement = document) => {
  const dataModuleEls = Array.from(container.querySelectorAll<HTMLElement>('[data-module]'))!;

  return Object.entries(modules)
    .map(([name, module]) => {
      // Find dom element
      const domNodes = dataModuleEls.filter((el) => {
        return el.dataset.module?.split(' ').includes(name);
      });

      // Bootstrap dom node if it exists
      const modulesInstances = domNodes.map(async (domNode) => {
        const Module = await module();
        return new Module(domNode);
      });

      return {[name]: modulesInstances};
    })
    .reduce((acc, val) => {
      return {...acc, ...val};
    }, {});
};
