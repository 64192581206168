import {clickOutside} from './clickOutside';
import {setAttributes} from './setAttributes';
import {twClassMerge} from './twClassMerge';

export interface DisclosureParams {
  domNode: HTMLElement;
  callback: (isOpen: boolean, disclosureEl: HTMLElement, btnEl: HTMLElement[], triggerBtn?: HTMLButtonElement) => void;
  onTransitionEnd?: (event: TransitionEvent, isOpen: boolean, disclosureEl: HTMLElement, btnEl: HTMLElement[]) => void;
  shouldSetVisibility?: boolean;
  isOpen?: boolean;
  closeOnClickOutside?: boolean;
}

export class Disclosure {
  dom;
  isOpen;
  private callback: DisclosureParams['callback'];
  private shouldSetVisibility;
  private onTransitionEnd;
  private closeOnClickOutside;
  private clickOutsideInstance;

  constructor({
    domNode,
    callback,
    onTransitionEnd = () => {},
    shouldSetVisibility = true,
    isOpen = false,
    closeOnClickOutside = true,
  }: DisclosureParams) {
    const id = domNode.dataset.id ? `disclosure-button-${domNode.dataset.id}` : 'disclosure-button';

    this.dom = {
      container: domNode,
      btn: Array.from(domNode.querySelectorAll<HTMLButtonElement>(`[data-element=${id}]`)!),
      disclosure:
        domNode.getAttribute('data-element') === 'disclosure-element'
          ? domNode
          : domNode.querySelector<HTMLElement>('[data-element=disclosure-element]')!,
    };

    this.callback = callback;
    this.onTransitionEnd = onTransitionEnd;
    this.shouldSetVisibility = shouldSetVisibility;
    this.isOpen = isOpen;
    this.closeOnClickOutside = closeOnClickOutside;

    if (this.closeOnClickOutside) {
      this.clickOutsideInstance = clickOutside([this.dom.disclosure, ...this.dom.btn], this.close);
    }
    this.init();
  }

  toggle = () => {
    this.isOpen = !this.isOpen;
    this.updateAttributes();
    this.callback(this.isOpen, this.dom.disclosure, this.dom.btn);
  };

  close = () => {
    if (this.isOpen) {
      this.isOpen = false;
      this.updateAttributes();
      this.callback(this.isOpen, this.dom.disclosure, this.dom.btn);
    }
  };

  destroy = () => {
    this.clickOutsideInstance?.();
    this.dom.btn.forEach((btn) => {
      btn.removeEventListener('click', this.handleToggle);
    });
    this.dom.disclosure.removeEventListener('transitionend', this.handleTransitionEnd);

    this.dom.container.removeEventListener('keydown', this.handleKeyDown);
  };

  private getButtonProps = (): Partial<HTMLButtonElement> => {
    return {
      // @ts-ignore
      'aria-expanded': `${this.isOpen}`,
    };
  };

  private updateAttributes = () => {
    this.dom.btn.forEach((btn) => {
      setAttributes(btn, this.getButtonProps());
    });
    if (this.shouldSetVisibility && this.isOpen) {
      twClassMerge(this.dom.disclosure, 'visible');
    }
  };

  private handleToggle = (event: MouseEvent) => {
    this.isOpen = !this.isOpen;
    this.updateAttributes();
    this.callback(this.isOpen, this.dom.disclosure, this.dom.btn, <HTMLButtonElement>event.currentTarget);
  };

  private handleTransitionEnd = (event: TransitionEvent) => {
    this.onTransitionEnd(event, this.isOpen, this.dom.disclosure, this.dom.btn);
    if (this.shouldSetVisibility && !this.isOpen) {
      twClassMerge(this.dom.disclosure, 'invisible');
    }
  };

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.close();
    }
  };

  private init = () => {
    this.dom.btn.forEach((btn) => {
      btn.addEventListener('click', this.handleToggle);
    });

    this.dom.disclosure.addEventListener('transitionend', this.handleTransitionEnd);

    this.dom.container.addEventListener('keydown', this.handleKeyDown);

    this.updateAttributes();
  };
}
