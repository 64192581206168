/**
 * Retrieves the inner HTML of a specific section in a fetched Shopify HTML response.
 *
 * @param htmlAsString - The HTML response as a string.
 * @param id - The ID of the section to retrieve.
 * @returns The inner HTML of the specified section, or an empty string if the section is not found.
 */
export const getFetchedShopifySectionById = (htmlAsString: string, id: string) => {
  return new DOMParser().parseFromString(htmlAsString, 'text/html').getElementById(id)?.innerHTML ?? '';
};
