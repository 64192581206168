/**
 * Sets the attributes of an HTML element.
 * 
 * @param element - The HTML element to set attributes on.
 * @param attributes - The attributes to set on the element.
 */
export const setAttributes = (element: HTMLElement, attributes: Partial<HTMLElement>): void => {
  Object.entries(attributes).forEach(([attr, value]) => {
    element.setAttribute(attr, value as string);
  });
};
