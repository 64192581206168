import {Disclosure} from '../utils/disclosure';
import {twClassMerge} from '../utils/twClassMerge';

export class Navigation {
  dom;
  drawer;
  subLevels;
  constructor(container: HTMLElement) {
    this.dom = {
      container,
      mainNavigation: document.getElementById('main-menu')!,
      subLevels: container.querySelectorAll<HTMLElement>('[data-element=navigation-sublevel]'),
      drawerIcon: container.querySelector<SVGElement>('.drawer-icon')!,
      drawerBtn: document.getElementById('drawer-btn')!,
    };

    const openLabel = this.dom.drawerBtn.getAttribute('aria-label')!;
    const closeLabel = this.dom.drawerBtn.dataset.closeAriaLabel!;
    this.drawer = new Disclosure({
      domNode: this.dom.container,
      callback: (isOpen, _disclosureEl) => {
        twClassMerge(this.dom.mainNavigation, isOpen ? 'translate-x-0' : '-translate-x-full');
        if (isOpen) {
          this.dom.drawerBtn.setAttribute('aria-label', closeLabel);
          this.dom.drawerIcon.classList.add('drawer-open');
        } else {
          this.dom.drawerBtn.setAttribute('aria-label', openLabel);
          this.dom.drawerIcon.classList.remove('drawer-open');
        }
      },
    });
    this.subLevels = Array.from(this.dom.subLevels).map((el: any) => {
      return new Disclosure({
        domNode: el,
        callback: (isOpen, disclosureEl) => {
          twClassMerge(disclosureEl, isOpen ? 'translate-x-0' : '-translate-x-full');
        },
      });
    });
  }
}
