/**
 * Attaches a click outside event listener to the specified element(s) and invokes the callback function
 * when a click occurs outside of the element(s).
 *
 * @param element - The element(s) to attach the click outside event listener to.
 * @param callback - The callback function to invoke when a click occurs outside of the element(s).
 * @returns A function that removes the click outside event listener.
 */
export const clickOutside = (element: Node | Node[], callback: () => void): (() => void) => {
  const handleClick = (event: MouseEvent): void => {
    const target = event.target as Node;
    const isClickInside: boolean = Array.isArray(element)
      ? element.some((e) => e.contains(target))
      : element.contains(target);

    if (!isClickInside) {
      // The click was OUTSIDE the specifiedElement, do something
      callback();
    }
  };
  document.addEventListener('click', handleClick);

  return () => {
    document.removeEventListener('click', handleClick);
  };
};
