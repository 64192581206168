import {Disclosure, DisclosureParams} from '../utils/disclosure';

export class Accordion {
  dom;
  items;
  options;
  callback;
  labels;

  constructor(domNode: HTMLElement, disclosureParams?: Partial<DisclosureParams>) {
    this.dom = {
      container: domNode,
      toggleBtn: domNode.querySelector<HTMLElement>('[data-element=toggle-button]')!,
    };

    this.options = {
      closeOnClickOutside: true,
    };

    this.labels = {
      expand: this.dom.container.dataset.expand!,
      collapse: this.dom.container.dataset.collapse!,
    };

    if (this.dom.container.dataset.closeOnClickOutside) {
      this.options.closeOnClickOutside = this.dom.container.dataset.closeOnClickOutside !== 'false';
    }

    this.callback = disclosureParams?.callback;

    this.items = Array.from(domNode.querySelectorAll<HTMLElement>('[data-element=accordion-item]')!).map((item) => {
      const disclosureEl = item.querySelector<HTMLElement>('[data-element=disclosure-element]')!;

      const height = disclosureEl.clientHeight;
      const initialHeight = disclosureEl.dataset.initialHeight ? parseInt(disclosureEl.dataset.initialHeight) : 0;

      disclosureEl.style.height = `${initialHeight}px`;

      return new Disclosure({
        ...disclosureParams,
        domNode: item,
        callback: this.handleToggle(height, initialHeight),
        closeOnClickOutside: this.options.closeOnClickOutside,
      });
    });
  }

  handleToggle: (height: number, initialHeight: number) => DisclosureParams['callback'] =
    (height, initialHeight) => (isOpen, disclosureEl, buttons) => {
      disclosureEl.style.height = isOpen ? `${height}px` : `${initialHeight}px`;
      buttons.forEach((button) => {
        button.ariaLabel = isOpen ? this.labels.collapse : this.labels.expand;
      });

      if (this.callback) {
        this.callback(isOpen, disclosureEl, buttons);
      }
    };
}
