export class Input {
  dom;
  private input: HTMLInputElement;
  private eyeIcon: HTMLElement | null;
  private eyeIconHidden: HTMLElement | null;

  constructor(container: HTMLElement) {
    this.dom = {
      container,
    };
    this.input = container.querySelector('input') as HTMLInputElement;
    this.eyeIcon = container.querySelector('.eye-icon');
    this.eyeIconHidden = container.querySelector('.eye-icon.hidden');

    this.attachEventHandlers();
  }

  private attachEventHandlers(): void {
    this.dom.container.querySelectorAll('[data-action="toggle-password-visibility"]').forEach((element) => {
      element.addEventListener('click', () => {
        this.togglePasswordVisibility();
      });
    });
  }

  togglePasswordVisibility(): void {
    if (this.input.type === 'password') {
      this.input.type = 'text';
      this.eyeIcon?.classList.add('hidden');
      this.eyeIconHidden?.classList.remove('hidden');
    } else {
      this.input.type = 'password';
      this.eyeIcon?.classList.remove('hidden');
      this.eyeIconHidden?.classList.add('hidden');
    }
  }
}
