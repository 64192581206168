import {asyncBootstrapper} from '@/utils/bootstrapper';

export class ProductRecommendations {
  productRecommendationsSections: NodeListOf<HTMLElement>;
  observers: IntersectionObserver[];

  constructor() {
    this.productRecommendationsSections = document.querySelectorAll<HTMLElement>('.product-recommendations');
    this.observers = Array.from(this.productRecommendationsSections).map(
      (section) =>
        new IntersectionObserver(this.generateIntersectionHandler(section), {rootMargin: '0px 0px 200px 0px'})
    );
    this.observers.forEach((observer, index) => observer.observe(this.productRecommendationsSections.item(index)));
  }

  generateIntersectionHandler = (section: HTMLElement) => {
    return (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
      if (!entries[0]?.isIntersecting) return;

      observer.unobserve(section);

      const url = section.dataset.url ?? '';
      if (url) {
        this.fetchProducts(url).then(() => {
          asyncBootstrapper({
            'carousel': () => import('@/ui/carousel').then((mod) => mod.Carousel),
          });
        });
      }
    };
  };

  fetchProducts = async (url: string) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const html = document.createElement('div');
      html.innerHTML = text;
      const recommendations = html.querySelector('.product-recommendations');
      if (recommendations && recommendations.innerHTML.trim().length) {
        (document.getElementById(recommendations.id) as HTMLElement).innerHTML = recommendations.innerHTML;
      }
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}
